import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Container, Row, Col, Form, Alert } from 'react-bootstrap';
import { UserContext } from '../../Contexts/UserProvider';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import Cookies from 'js-cookie';
import './Subscriptions.css';
import 'animate.css';
import CheckoutForm from '../CheckoutForm/CheckoutForm';

const stripePromise = loadStripe('pk_live_51HqH5iHzuQ2rCeWElc5rODbceMKvKwb7OCN4vL547PJ2ZYcXMOADJ2N7XOn0OlzllhM6lVq7mWHLzQdbcLdbtbCf00I1NGJ8oX');

function Subscriptions(props) {
    const { user, refreshUserData } = useContext(UserContext);
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [err, setErr] = useState('');
    const paymentRef = useRef();
    const categoriesRef = useRef();
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loadingQuiz, setLoadingQuiz] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState(props.selectedSubscription);
    const [showCheckout, setShowCheckout] = useState(props.selectedSubscription ? true : false);
    const [quizOptions, setQuizOptions] = useState({
        quizOption: "50",
        timedTestOption: "90",
        numQuestions: "50",
        categoryId: [],
        type: "unansweredOnly",
    });

    const fetchCategories = async () => {
        const token = Cookies.get("token"); // Get token from cookie
        if (!token) {
            navigate("/login");
            return;
        }
        try {
            const categoriesResponse = await axios.get("https://api.reviseukmla.com/api/v1/category/getCategories", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (categoriesResponse.status === 200) {
                const fetchedCategories = categoriesResponse.data.data.categories;
                setCategories(fetchedCategories);
                setLoadingCategories(false);
            }
        } catch (err) {
            setErr(err.message);
        }
    };

    useEffect(() => {
        if (user && (user.subscription === "targetedApproach" || user.subscription === "thoroughPractice") && new Date(user.subscriptionExpires) > new Date()) {
            fetchCategories(user);
        } else if (!selectedSubscription) {
            navigate('/')
        }
        if (quizOptions.quizOption === "category" && categoriesRef.current) {
            categoriesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const handleSubmit = async (e) => {
        setLoadingQuiz(true);
        setErr("");
        e.preventDefault();
        const { quizOption, timedTestOption, categoryId, type } = quizOptions;
        let requestData = {
            categories: [],
            numQuestions: 10,
            type: type
        };

        if (quizOption === "timedTest") {
            if (timedTestOption === "") {
                setErr("Please select a time duration for the timed test");
                setLoadingQuiz(false);
                return;
            }
            requestData.numQuestions = parseInt(timedTestOption);
        } else if (quizOption === "category") {
            if (categoryId.length === 0) {
                setErr("Please select at least one category");
                setLoadingQuiz(false);
                return;
            }
            requestData.categories = categoryId;
            requestData.numQuestions = 99999;
        } else {
            requestData.numQuestions = parseInt(quizOption);
        }

        if (quizOption !== "category") {
            requestData.categories = categories.map((cat) => cat._id);
        }

        const token = Cookies.get("token"); // Get token from cookie
        if (!token) {
            navigate("/login");
            return;
        }

        try {
            const response = await axios.post(
                "https://api.reviseukmla.com/api/v1/quiz/startQuiz",
                requestData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            setLoadingQuiz(false);

            if (response.status === 200) {
                localStorage.setItem("submittedAnswers", JSON.stringify(response.data.data.questions.map(() => 0)));
                localStorage.setItem("selectedOptions", JSON.stringify(response.data.data.questions.map(() => null)));
                localStorage.setItem("correctAnswers", JSON.stringify(response.data.data.questions.map(() => null)));
                localStorage.setItem("topics", JSON.stringify(response.data.data.questions.map(() => null)));
                localStorage.setItem("categoryResults", JSON.stringify({}));
                localStorage.setItem("countdownDuration", 0);
                if (quizOption === "timedTest") {
                    localStorage.setItem("countdownDuration", parseInt(timedTestOption) * 60);
                    localStorage.setItem('timerStart', Date.now().toString());
                }
                localStorage.setItem("questions", JSON.stringify(response.data.data.questions));
                navigate("/quiz/#quiz");
            } else {
                setErr("Failed to start quiz");
            }
        } catch (err) {
            setErr(err.message);
            setLoadingQuiz(false);
        }
    };

    const handleToken = async (token) => {
        setMessage("");
        setErr('');

        const authToken = Cookies.get('token');
        try {
            const response = await axios.post(
                'https://api.reviseukmla.com/api/v1/subscription/buySubscription',
                { token, subscription: selectedSubscription },
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.status === "success") {
                setMessage('Payment Successful');
                refreshUserData();
                setSelectedSubscription(null);
                // navigate('/subscriptions');
            } else {
                setErr('Payment Failed');
            }
        } catch (error) {
            setErr('Payment Failed');
        }
    };

    const handleCategoryChange = (e) => {
        const categoryId = e.target.value;
        let updatedSelectedCategories;
        if (quizOptions.categoryId.includes(categoryId)) {
            updatedSelectedCategories = quizOptions.categoryId.filter((id) => id !== categoryId);
        } else {
            if (quizOptions.categoryId.length >= 3) {
                setErr("You can select up to 3 categories only.");
                return;
            }
            updatedSelectedCategories = [...quizOptions.categoryId, categoryId];
        }

        setErr("");
        setQuizOptions({
            ...quizOptions,
            categoryId: updatedSelectedCategories,
        });
    };

    const handleQuizOptionsChange = (e) => {
        const option = e.target.value;
        setQuizOptions((prevOptions) => ({
            ...prevOptions,
            [e.target.name]: option
        }));
        if (categoriesRef.current) {
            categoriesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Container className="mt-5 subscriptions-main">
            <h1 className='headline'>Start studying</h1>
            {(user) && (user.subscription === "targetedApproach" || user.subscription === "thoroughPractice") && new Date(user.subscriptionExpires) > new Date() ? (
                <div style={{ marginBottom: '50px' }}>
                    <Row className="justify-content-center">
                        {quizOptions.quizOption === "category" && <Col md={3} />}
                        <Col md={quizOptions.quizOption === "category" ? 6 : 12} className='animate__animated animate__fadeInRight'>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="quizOptions">
                                    <Form.Label><strong>Select one from below:</strong></Form.Label>
                                    <Form.Check
                                        type="radio"
                                        className='inp-cbx'
                                        label="Set of 50 questions"
                                        name="quizOption"
                                        value="50"
                                        onChange={handleQuizOptionsChange}
                                        checked={quizOptions.quizOption === "50"}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Set of 100 questions"
                                        name="quizOption"
                                        value="100"
                                        onClick={handleQuizOptionsChange}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Area of clinical specialty"
                                        name="quizOption"
                                        value="category"
                                        onClick={handleQuizOptionsChange}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Timed test"
                                        name="quizOption"
                                        value="timedTest"
                                        onClick={handleQuizOptionsChange}
                                    />
                                </Form.Group>
                                {quizOptions.quizOption === 'timedTest' && (
                                    <Form.Group controlId="timedTestOptions" className='animate__animated animate__fadeInDown animate__faster mt-3'>
                                        <Form.Label><strong>Select one:</strong></Form.Label>
                                        <Form.Check
                                            type="radio"
                                            label="90 questions in 90 minutes"
                                            name="timedTestOption"
                                            onChange={handleQuizOptionsChange}
                                            value="90"
                                            checked={quizOptions.timedTestOption === "90"}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="180 questions in 3 hours"
                                            name="timedTestOption"
                                            value="180"
                                            onChange={handleQuizOptionsChange}
                                            checked={quizOptions.timedTestOption === "180"}
                                        />
                                    </Form.Group>
                                )}
                                {quizOptions.quizOption !== "" && (
                                    <Form.Group controlId="type" className='mt-3'>
                                        <Form.Label><strong>Choose from one of the following options:</strong></Form.Label>
                                        <Form.Check
                                            type="radio"
                                            label="Show new questions only"
                                            name="type"
                                            value="unansweredOnly"
                                            onChange={handleQuizOptionsChange}
                                            checked={quizOptions.type === "unansweredOnly"}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Show all, including new and previous incorrect questions"
                                            name="type"
                                            onChange={handleQuizOptionsChange}
                                            value="unansweredAndIncorrect"
                                            checked={quizOptions.type === "unansweredAndIncorrect"}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="Show incorrect questions only"
                                            name="type"
                                            onChange={handleQuizOptionsChange}
                                            value="incorrectOnly"
                                            checked={quizOptions.type === "incorrectOnly"}
                                        />
                                    </Form.Group>
                                )}
                                {quizOptions.quizOption !== "category" && <Button className='mt-3' variant="primary" type="submit">
                                    Start Studying
                                </Button>}
                                {loadingQuiz && (
                                    <div className="spinner-border text-primary" role="status" style={{ marginLeft: "47%", marginTop: "20px" }}>
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                )}
                            </Form>
                        </Col>
                        {quizOptions.quizOption === "category" && <Col md={3} />}
                    </Row>
                    {quizOptions.quizOption === "category" ? <Row ref={categoriesRef} className='mt-4 start-quiz-container animate__animated animate__fadeInDown categories'>
                        <h2>Select Category:</h2>
                        {loadingCategories === true ? (
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <>
                                <Col md={6}>
                                    {categories.slice(0, Math.ceil(categories.length / 2)).map((category) => (
                                        <div className="checkbox-wrapper-46" key={category._id}>
                                            <input
                                                type="checkbox"
                                                id={`cbx-${category._id}`}
                                                name="categoryId"
                                                className="inp-cbx"
                                                value={category._id}
                                                onChange={handleCategoryChange}
                                                checked={quizOptions.categoryId.includes(category._id)}
                                            />
                                            <label htmlFor={`cbx-${category._id}`} className="cbx">
                                                <span>
                                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                    </svg>
                                                </span>
                                                <span>{category.name}</span>
                                            </label>
                                        </div>
                                    ))}
                                </Col>
                                <Col md={6}>
                                    {categories.slice(Math.ceil(categories.length / 2)).map((category) => (
                                        <div className="checkbox-wrapper-46" key={category._id}>
                                            <input
                                                type="checkbox"
                                                id={`cbx-${category._id}`}
                                                name="categoryId"
                                                className="inp-cbx"
                                                value={category._id}
                                                onChange={handleCategoryChange}
                                                checked={quizOptions.categoryId.includes(category._id)}
                                            />
                                            <label htmlFor={`cbx-${category._id}`} className="cbx">
                                                <span>
                                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                    </svg>
                                                </span>
                                                <span>{category.name}</span>
                                            </label>
                                        </div>
                                    ))}
                                </Col>
                            </>
                        )}
                        <button className="btn btn-primary custombutton mt-3" type="submit" onClick={handleSubmit}>
                            Start Studying
                        </button>
                        {err !== "" ? (
                            <Alert key="danger" variant="danger">
                                {err}
                            </Alert>
                        ) : null}
                        {message !== "" ? (
                            <Alert key="success" variant="success">
                                {message}
                            </Alert>
                        ) : null}
                        {/* <Col md={5} className="quiz-settings">
                                    <form onSubmit={handleSubmit}>
                                        <div className='mb-3'>
                                            <label>Number of Questions (1-200):</label>
                                            <input
                                                type="number"
                                                name="numQuestions"
                                                value={quizOptions.numQuestions}
                                                onChange={handleQuizOptionsChange}
                                                min="1"
                                                max="200"
                                            />
                                        </div>
                                    </form>
                                </Col> */}
                    </Row> : <Row ref={categoriesRef}></Row>}
                </div>
            ) : (
                <div className='subscriptions-main'>
                    {showCheckout ? (
                        <Row id='payment' ref={paymentRef}>
                            <Col md={1} />
                            <Col md={10}>
                                <p className='mb-4'>By choosing a subscription plan, you agree to our terms and conditions. Please note that your subscription will automatically expire at the end of the {selectedSubscription ? selectedSubscription.duration : 6}-month period.</p>
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm
                                        handleToken={handleToken}
                                        amount={selectedSubscription.amount}
                                        subscriptionType={selectedSubscription.subscriptionType}
                                    />
                                </Elements>
                            </Col>
                            <Col md={1} />
                        </Row>
                    ) : (
                        // Render your subscription options here if needed
                        <Row ref={paymentRef}>
                        </Row>
                    )}
                    {err && (
                        <Alert variant="danger" style={{ "width": "83.33%" }}>
                            {err}
                        </Alert>
                    )}
                    {message && (
                        <Alert variant="success" style={{ "width": "83.33%" }}>
                            {message}
                        </Alert>
                    )}
                </div>
            )}
        </Container>
    );
}

export default Subscriptions;
